import React from 'react'
import { FastField } from 'formik'
import { css } from 'emotion'

export default ({ id, label, type, render, className, ...props }) => {
  if (!id) {
    return 'no id for this field'
  }

  return label ? (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        label {
          margin-bottom: 12px;
        }
        input {
          margin-bottom: 20px;
          height: 51px;
          font-size: 16px;
          letter-spacing: 0.1px;
          line-height: 19px;
          border: 1px solid #d5dadf;
          background-color: #ffffff;
          padding-left: 10px;
        }
        textarea {
          margin-bottom: 20px;
          height: 151px;
          font-size: 16px;
          letter-spacing: 0.1px;
          line-height: 19px;
          border: 1px solid #d5dadf;
          background-color: #ffffff;
          padding-left: 10px;
          padding-top: 10px;
        }
      `}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <FastField
        name={id}
        id={id}
        type={type}
        render={render}
        className={className}
        {...props}
      />
    </div>
  ) : (
    <FastField
      name={id}
      id={id}
      render={render}
      className={className}
      {...props}
    />
  )
}
