import React from 'react'
import { Formik, Form, FieldArray } from 'formik'
import * as yup from 'yup'
import { css } from 'emotion'
import { Persist } from 'formik-persist'
import { format } from 'date-fns'
import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/ultramin'
import Layout from '../layout'
import { Container } from '../../components/markupHelpers'
import NotesFieldArray from './NotesFieldArray'
import InputField from './InputField'
import Button from '../Button'
import Dropdown from './Dropdown.js'
import { UserContext } from '../../context/userContext'

const courseReviewSchema = yup.object().shape({
  slug: yup.string().required(),
  dependencies: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      version: yup.string(),
    }),
  ),
  reviews: yup.array().of(
    yup.object().shape({
      performedOn: yup.string(),
      performedBy: yup.number(),
      scopeOfReview: yup.string(),
      notes: yup.array().of(
        yup.object().shape({
          type: yup.string(),
          dependency: yup.string(),
          title: yup.string(),
          details: yup.string(),
          lessons: yup.array().of(
            yup.object().shape({
              slug: yup.string(),
            }),
          ),
        }),
      ),
    }),
  ),
})

class Review extends React.Component {
  toggleShowJsonView = e => {
    e.preventDefault()
    this.setState(state => ({ showJsonView: !state.showJsonView }))
  }

  transformJsonOutput = values => {
    const dependencies = values.dependencies.reduce(
      (formattedDependency, formValue) => {
        const dependency = {
          [formValue.name]: formValue.version,
        }
        return { ...formattedDependency, ...dependency }
      },
      {},
    )

    return {
      ...values,
      dependencies,
    }
  }

  render() {
    return (
      <Layout>
        <UserContext.Consumer>
          {({ user }) => {
            return (
              <Container maxWidth={1180}>
                <Formik
                  initialValues={{
                    slug: '',
                    dependencies: [],
                    reviews: [],
                  }}
                  validationSchema={courseReviewSchema}
                  onSubmit={(values, actions) => {
                    console.log({ values, actions })
                  }}
                >
                  {({ values, setFieldValue }) => {
                    return (
                      <Container maxWidth={980}>
                        <Form
                          className={css`
                            display: flex;
                            flex-direction: column;
                          `}
                        >
                          <InputField id="slug" label="Course Slug" />{' '}
                          <FieldArray
                            name="reviews"
                            render={arrayHelpers => (
                              <>
                                {values.reviews && values.reviews.length > 0 ? (
                                  values.reviews.map((review, index) => (
                                    <div key={`reviews-${index}`}>
                                      <div
                                        className={css`
                                          display: flex;
                                          flex-direction: row;
                                          justify-content: space-between;
                                        `}
                                      >
                                        <InputField
                                          id={`reviews.${index}.performedOn`}
                                          label="Review Performed On"
                                        />
                                        <Dropdown
                                          value={
                                            values.reviews[index].scopeOfReview
                                          }
                                          onChange={val => {
                                            setFieldValue(
                                              `reviews[${index}].scopeOfReview`,
                                              val,
                                            )
                                          }}
                                          id={`reviews[${index}].scopeOfReview`}
                                          label="Scope of Review"
                                          options={[
                                            {
                                              value: 'full',
                                              label: 'Full Course Review',
                                            },
                                            {
                                              value: 'lesson',
                                              label: 'Select Lessons Review',
                                            },
                                            {
                                              value: 'quick',
                                              label: 'Quick Overview Review',
                                            },
                                          ]}
                                        />
                                      </div>
                                      <NotesFieldArray
                                        id={`reviews.${index}.notes`}
                                        values={values}
                                        review={review}
                                      />
                                    </div>
                                  ))
                                ) : (
                                  <div
                                    className={css`
                                      display: flex;
                                      justify-content: center;
                                    `}
                                  >
                                    <Button
                                      blue
                                      onClick={() =>
                                        arrayHelpers.push({
                                          performedOn: format(
                                            Date.now(),
                                            'YYYY-MM-DD',
                                          ),
                                          performedBy: user.id,
                                          scopeOfReview: 'full',
                                          notes: [],
                                        })
                                      }
                                    >
                                      {/* show this when user has removed all dependencies from the list */}
                                      Add Review
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                          />
                          <div
                            className={css`
                              padding-top: 20px;
                            `}
                          >
                            <Highlight
                              {...defaultProps}
                              theme={theme}
                              code={JSON.stringify(
                                this.transformJsonOutput(values),
                                null,
                                4,
                              )}
                              language="json"
                            >
                              {({
                                className,
                                style,
                                tokens,
                                getLineProps,
                                getTokenProps,
                              }) => (
                                <pre
                                  className={css`
                                    text-align: left;
                                    margin: 1em 0;
                                    padding: 0.5em;

                                    & .token-line {
                                      line-height: 1.3em;
                                      height: 1.3em;
                                    }
                                  `}
                                  style={style}
                                >
                                  {tokens.map((line, i) => (
                                    <div {...getLineProps({ line, key: i })}>
                                      <span
                                        className={css`
                                          display: inline-block;
                                          width: 2em;
                                          user-select: none;
                                          opacity: 0.3;
                                        `}
                                      >
                                        {i + 1}
                                      </span>
                                      {line.map((token, key) => (
                                        <span
                                          {...getTokenProps({ token, key })}
                                        />
                                      ))}
                                    </div>
                                  ))}
                                </pre>
                              )}
                            </Highlight>
                          </div>
                          <Persist name="content-review" />
                        </Form>
                      </Container>
                    )
                  }}
                </Formik>
              </Container>
            )
          }}
        </UserContext.Consumer>
      </Layout>
    )
  }
}

export default Review
