import React from 'react'
import Select from 'react-select'
import { css } from 'emotion'

class Dropdown extends React.Component {
  render() {
    const { value, onChange, id, label, options, ...rest } = this.props
    return (
      <div>
        <label htmlFor={id}>{label}</label>
        <Select
          options={options}
          value={{ label: value }}
          onChange={option => onChange(option.value)}
          styles={{
            control: styles => ({
              ...styles,
              marginTop: 12,
              width: 179,
              height: 51,
            }),
          }}
        />
      </div>
    )
  }
}

export default Dropdown
