import React from 'react'
import { FieldArray, FastField } from 'formik'
import { css } from 'emotion'
import Button from '../Button'
import RubishBin from '../../images/icons/rubbish-bin.svg'

export default ({ id, parent, className, addNote }) => {
  return (
    <FieldArray
      name={id}
      className={className}
      render={dependencyArrayHelpers => (
        <div>
          {parent.dependencies && parent.dependencies.length > 0 ? (
            parent.dependencies.map((dependency, index) => {
              const dependencyId = `${id}.${index}`
              return (
                <div key={`${dependencyId}-${index}`}>
                  <div
                    className={css`
                      display: flex;
                      flex-direction: column;
                      margin-bottom: 20px;
                    `}
                  >
                    <div
                      className={css`
                        display: flex;
                        flex-direction: row;
                      `}
                    >
                      <label
                        htmlFor={`{${dependencyId}.name`}
                        className={css`
                          flex-grow: 1;
                        `}
                      >
                        Name
                      </label>
                      <label
                        htmlFor={`${dependencyId}.version`}
                        className={css`
                          width: 237px;
                        `}
                      >
                        Version
                      </label>
                    </div>
                    <div
                      className={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      `}
                    >
                      <FastField
                        className={css`
                          flex-grow: 1;
                          height: 51px;
                          font-size: 16px;
                          letter-spacing: 0.1px;
                          line-height: 19px;
                          border: 1px solid #d5dadf;
                          background-color: #ffffff;
                          padding-left: 10px;
                        `}
                        name={`${dependencyId}.name`}
                        id={`${dependencyId}.name`}
                      />
                      <FastField
                        className={css`
                          width: 192px;
                          height: 51px;
                          font-size: 16px;
                          letter-spacing: 0.1px;
                          line-height: 19px;
                          border: 1px solid #d5dadf;
                          background-color: #ffffff;
                          padding-left: 10px;
                        `}
                        name={`${dependencyId}.version`}
                        id={`${dependencyId}.version`}
                      />
                      <button
                        className={css`
                          border: 1px solid #ff3b30;
                          font-size: 14px;
                          letter-spacing: 0.09px;
                          line-height: 16px;
                          height: 51px;
                          width: 51px;
                          font-size: 30px;
                          background-color: white;
                        `}
                        type="button"
                        onClick={() => dependencyArrayHelpers.remove(index)} // remove a dependency from the list
                      >
                        <img alt="Delete Icon" src={RubishBin} height="20px" />
                      </button>
                    </div>
                  </div>
                  <div
                    className={css`
                      display: flex;
                      flex-direction: row;
                      justify-content: ${index ===
                      parent.dependencies.length - 1
                        ? 'space-between'
                        : 'flex-end'};
                      margin-top: 10px;
                    `}
                  >
                    {index === parent.dependencies.length - 1 && (
                      <Button
                        white
                        onClick={() =>
                          dependencyArrayHelpers.push({
                            name: '',
                            version: '',
                          })
                        } // insert an dependency at a position
                      >
                        + Dependency
                      </Button>
                    )}
                    {dependency.name.length > 0 && (
                      <Button
                        white
                        onClick={() => addNote({ dependency: dependency.name })}
                      >
                        Note Issue
                      </Button>
                    )}
                  </div>
                </div>
              )
            })
          ) : (
            <Button
              white
              onClick={() =>
                dependencyArrayHelpers.push({
                  name: '',
                  version: '',
                })
              }
            >
              + Dependency
            </Button>
          )}
        </div>
      )}
    />
  )
}
