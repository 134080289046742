import React from 'react'
import { FieldArray } from 'formik'
import { css } from 'emotion'
import { Container } from '../markupHelpers'
import DependenciesFieldArray from './DependenciesFieldArray'
import InputField from './InputField'
import Button from '../Button'
import RubishBin from '../../images/icons/rubbish-bin.svg'

export default ({ values, id, review }) => {
  return (
    <FieldArray
      name={id}
      render={arrayHelpers => {
        return (
          <>
            <DependenciesFieldArray
              id="dependencies"
              parent={values}
              addNote={({ dependency }) =>
                arrayHelpers.unshift({
                  type: '',
                  title: '',
                  details: '',
                  lessons: [],
                  dependency,
                })
              }
            />
            <Container maxWidth={940}>
              {review.notes &&
                review.notes.length > 0 &&
                review.notes.map((note, noteIndex) => {
                  const noteId = `${id}.${noteIndex}`
                  return (
                    <div
                      key={noteId}
                      className={css`
                        padding: 20px;
                        border-bottom: 1px solid #f7f8f9;
                        display: flex;
                        flex-direction: column;
                      `}
                    >
                      <h2
                        className={css`
                          text-transform: capitalize;
                        `}
                      >
                        {note.dependency}
                      </h2>
                      <div
                        className={css`
                          margin-bottom: 20px;
                          padding: 24px;
                          background-color: #f7f8f9;
                        `}
                      >
                        <InputField
                          id={`${noteId}.title`}
                          label={'Issue Title'}
                        />
                        <InputField
                          id={`${noteId}.details`}
                          label={'Issue Details'}
                          render={({ field, _form }) => <textarea {...field} />}
                        />
                        <InputField
                          id={`${noteId}.type`}
                          label={'Issue Type'}
                          placeholder={'"major issue" or "minor issue"'}
                        />
                        <FieldArray
                          name={`${noteId}.lessons`}
                          render={lessonArrayHelpers => {
                            return (
                              <div
                                className={css`
                                  margin-top: 20px;
                                `}
                              >
                                {note.lessons && note.lessons.length > 0 && (
                                  <h2>Affected Lesson(s)</h2>
                                )}
                                {note.lessons && note.lessons.length > 0 ? (
                                  note.lessons.map((lesson, lessonIndex) => (
                                    <>
                                      <div
                                        className={css`
                                          display: flex;
                                          flex-direction: column;
                                          width: 100%;
                                          align-content: flex-end;
                                        `}
                                      >
                                        <InputField
                                          key={`${noteId}.lessons.${lessonIndex}`}
                                          id={`${noteId}.lessons.${lessonIndex}`}
                                          label={'Lesson Slug'}
                                        />
                                        <div
                                          className={css`
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: space-between;
                                          `}
                                        >
                                          {lessonIndex ===
                                            note.lessons.length - 1 && (
                                            <Button
                                              white
                                              type="button"
                                              onClick={() =>
                                                lessonArrayHelpers.push('')
                                              }
                                            >
                                              New Affected Lesson
                                            </Button>
                                          )}
                                          <Button
                                            red
                                            type="button"
                                            onClick={() =>
                                              lessonArrayHelpers.remove(
                                                lessonIndex,
                                              )
                                            }
                                          >
                                            <div
                                              className={css`
                                                display: flex;
                                                flex-direction: row;
                                                align-items: center;
                                                justify-content: center;
                                              `}
                                            >
                                              <img
                                                src={RubishBin}
                                                alt="Delete Icon"
                                                height="20px"
                                                className={css`
                                                  padding-right: 5px;
                                                  fill: #ff3b30;
                                                `}
                                              />
                                              Delete Lesson
                                            </div>
                                          </Button>
                                        </div>
                                      </div>
                                    </>
                                  ))
                                ) : (
                                  <Button
                                    white
                                    onClick={() => lessonArrayHelpers.push('')}
                                    type="button"
                                  >
                                    New Affected Lesson
                                  </Button>
                                )}
                              </div>
                            )
                          }}
                        />
                      </div>
                      <Button
                        red
                        type="button"
                        onClick={() => arrayHelpers.remove(noteIndex)}
                      >
                        <div
                          className={css`
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                          `}
                        >
                          <img
                            src={RubishBin}
                            alt="Delete Icon"
                            height="20px"
                            className={css`
                              padding-right: 5px;
                              fill: #ff3b30;
                            `}
                          />
                          Delete Issue
                        </div>
                      </Button>
                    </div>
                  )
                })}
            </Container>
          </>
        )
      }}
    />
  )
}
